@import '~antd/dist/antd.css';

.ant-layout {
  background: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f0f0;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #f01428;
}

.ant-menu-item a:hover {
  color: #f6727e;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #f01428;
}

.ant-layout-sider-zero-width-trigger {
  background: #f01428;
  top: 0px;
}

.header-button-1 {
  margin-right: 10px;
}

.green-btn {
  background-color: #00a854;
  color: #fff;
}

.green-btn:hover {
  background-color: #0dd873;
  color: #fff;
}

.title-section {
  margin-bottom: 20px;
}

.submit-button {
  margin-top: 4%;
}

.subtext {
  color: grey;
}

.ant-table {
  overflow-x: auto !important;
}

.ant-tag {
  border-radius: 5px;
}

/* On mobile, make the site layout have top padding of 4% */
@media screen and (max-width: 767px) {
  .site-layout {
    padding-top: 8%;
  }
}
